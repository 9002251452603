export const menu = () => {
  const hamburgerBtn = document.getElementById("hamburger-btn");
  const menuEl = document.getElementById("menu");
  const menuLinks = menuEl.querySelectorAll('a[href^="#"]');
  const menuLinksArr = Array.prototype.slice.call(menuLinks);
  const body = document.body;

  hamburgerBtn.addEventListener("click", () => {
    body.classList.toggle("is-menu-open");
  });

  menuLinksArr.forEach((menuLink) => {
    menuLink.addEventListener("click", closeMenu);
  });

  function closeMenu() {
    body.classList.remove("is-menu-open");
  }
};
