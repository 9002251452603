export const inview = (targetClass, activeClass) => {
  const inviewEls = document.querySelectorAll(`.${targetClass}`);
  const inviewElsArr = Array.prototype.slice.call(inviewEls);

  const cb = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        /* 監視対象が画面内に入ったときのアクション */
        entry.target.classList.add(activeClass); // 画面内に入った要素にinviewクラスを付与
        observer.unobserve(entry.target); // 1度発火した後監視を止める
      }
    });
  };

  const options = {
    rootMargin: "-20% 0px",
  };

  const io = new IntersectionObserver(cb, options);
  io.POLL_INTERVAL = 100;

  inviewElsArr.forEach((el) => {
    io.observe(el);
  });
};
