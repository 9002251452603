import { header } from "./modules/header";
import { menu } from "./modules/menu";
import { smoothScroll } from "./modules/smoothScroll";
import { inview } from "./modules/inview";

window.addEventListener("DOMContentLoaded", () => {
  header();
  menu();
  smoothScroll();
  inview("js-fadeup", "fadeup-is-active");
  inview("js-scale-up", "scale-up-is-active");
  inview("js-inview", "inview-is-active");
});
