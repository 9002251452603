export const header = () => {
  const body = document.body;
  const headerEl = document.getElementById("header");
  const mv = document.getElementById("mv");

  const cb = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        body.classList.remove("is-detached");
      } else {
        body.classList.add("is-detached");
      }
    });
  };

  const options = {
    rootMargin: "-50px 0px",
  };

  const io = new IntersectionObserver(cb, options);
  io.POLL_INTERVAL = 100;
  io.observe(mv);
};
